/* eslint-disable */
import { BehaviorSubject } from "rxjs";
import { filter, take, map } from "rxjs/operators";

const subject = new BehaviorSubject(null);

export default {
  getState: (featureKey, defaultvalue = false) => {
    return subject.pipe(
      filter((features) => features !== null),
      take(1),
      map(featuresData => {
        const features = featuresData.filter((x) => x.systemName == featureKey);
        return features.length == 0 ? defaultvalue : features[0].isEnabled;
      })
    );
  },

  update: (features) => {
    subject.next(features);
  },
};
