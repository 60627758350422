import { showWhenPrefix } from "./root-methods.util";

/* eslint-disable */
export const utils = {

  handle404s: function (singleSpa) {
    window.addEventListener('single-spa:routing-event', () => {
      const contentHolders = ['home-app', 'angular-app', 'angular-app-with-routing', 'react-app', 'react-auth-app', 'tax-filer-holder-app', 'senta-react-holder'];
      let contentHoldersUsed = [];
      contentHolders.forEach(function (item) {
        if (document.getElementById(item).innerHTML !== '') { contentHoldersUsed.push({'holder': item}) }
      });

      if (contentHoldersUsed.length === 0) { // no other content? Check for Senta content
        /**
         * The Senta Angular package may remain 'mounted' in order to maintain state for performance
         * reasons, however, it's still not 'shallow empty' so instead we specifically check that
         * it is mounted and is rendering content.
         */
        const sentaIsMounted = !!document.querySelector('#senta-angular-holder div');
        const sentaHasContent = !document.getElementById('senta-no-content');
        if (sentaIsMounted && sentaHasContent) {
          contentHoldersUsed.push({ holder: 'senta-angular-holder'})
        }
      }

      const routesWithNoContentHolder = [
        "/login",
        "/logout",
        "/download",
        "/att"
      ];

      let isRouteWithNoContentHolder = showWhenPrefix(routesWithNoContentHolder)(window.location);

      if (contentHoldersUsed.length === 0 && !isRouteWithNoContentHolder) {
        singleSpa.navigateToUrl("/404");
      }
    });
  }
}
