
import * as singleSpa from 'single-spa';
import eventBus from './../event-bus';
import features from './../features';

// root application utils
import {
  showWhenAnyOf,
  showWhenPrefix,
  showExcept,
  load
} from './../root-methods.util';

/**
 * The Elements hostapp has a few things that apply for both the practice side and the client side (client portal).
 */

export default function appCommon(environment) {
  console.log('hostApp: loading packages required for all user types. app-common.js loaded.');


  singleSpa.registerApplication(
    'login',
    () => load('@iris/elements2-authentication-signin-component-v01-pkg'),
    showWhenPrefix(['/login', '/logout', '/logincallback', '/session-signout', '/missing-profile']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'signup',
    () => System.import('@iris/elements2-authentication-signup-component-v01-pkg').then(m => m.default),
    showWhenAnyOf(['/signup', '/signup/']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'authentication-session-manager',
    () => System.import('@iris/elements2-authentication-session-manager-component-pkg').then(m => m),
    showExcept(['/login', '/signup', '/logout']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'global-events-manager',
    () => System.import('@iris/elements2-global-events-manager-component-pkg').then(m => m),
    showExcept(['/login', '/signup', '/logout']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'platform-ui-logging',
    () => System.import('@iris/platform-ui-logging-component-pkg').then(m => m),
    showExcept(['/login', '/signup', '/logout']),
    { environment, eventBus, features }
  );
}
