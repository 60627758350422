import { setFeatures } from '/web_modules/@iris/communications-feature-switching-pkg/dist/index.js';
import developmentFeatures from './features.development.json';
import testingFeatures from './features.testing.json';
import stagingFeatures from './features.staging.json';
import productionFeatures from './features.production.json';

// Call the setDevFeatures(environment)
// when your application initializes and then
export const setDevFeatures = (environment) => {
    setFeatures({ 
        features: {
            development: developmentFeatures,
            testing: testingFeatures,
            staging: stagingFeatures,
            production: productionFeatures
        }, 
        environment
    });
};