import * as singleSpa from 'single-spa';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { setDevFeatures } from './features-dev';
import eventBus from './event-bus';
import { utils } from './utils';

import appCommon from './src/app-common';
import appMain from './src/app-main';
import appClientPortal from './src/app-client-portal';

import features from './features';

let localEnvironment = {}

System.import('@iris/environment')
  .then(m => m.default)
  .then((environment) => {
    window.environment = environment;
    localEnvironment = environment;
    console.log('hostApp: root-application.js file loaded.');

    return Promise.allSettled([
      System.import('@iris/platform-ui-kit-pkg'),
      System.import('@iris/elements2-platform-ui-kit-widgets-pkg'),
      System.import('@iris/accountsproduction-ui-components-pkg'),
      System.import('@iris/elements2-tax-widgets-v01-pkg'),
      System.import('@iris/platform-ui-core-utility-pkg')
        .then(m => {
          m.setFeatures(features);
        })
    ])
  })
  .then(() => {
    // Set the environment so that the development feature switches
    // know which one it is to show/hide the respective parts of the host app
    setDevFeatures(localEnvironment);

    // Load the packages that we need for both the host-app itself (eg. signup, login, etc) and the practice and client apps.
    appCommon(localEnvironment);

    // Login redirect if needed, otherwise determine type of user and which packages to load.
    // This also ensures that by the time child packages are loaded there exists user/auth data
    // and so resolves a race condition and the need for children to individually each handle that scenario.
    // [a practice user (standard) or client user (client portal packages)]
    try {
      const iasUserData = JSON.parse(localStorage.getItem('ias_user'))

      checkCanInit(iasUserData);
      initApp(iasUserData.userType, localEnvironment);

    } catch (e) {
      console.log(e?.message ?? e)
      eventBus.on('user.loggedin', () => {
        console.log(`hostApp: Recieved 'user.loggedin' event to confirm user has logged in. Registering appropriate packages...`);
        initApp(undefined, localEnvironment);
      });
      // If we're not already in part of the auth flow, then go to the login page.
      const path = window.location.pathname
      if (shouldRedirectToLogin(path)) {
        let qps = ''
        if (shouldReturnTo(path)) {
          qps = `?returnTo=${encodeURIComponent(path + window.location.search + window.location.hash)}`
        }
        singleSpa.navigateToUrl(`/login${qps}`);
      }
    }


    utils.handle404s(singleSpa);
    singleSpa.start();
  });

/**
 * ??????????
 */
window.addEventListener('single-spa:routing-event', () => {
  // hide the old login screen from some migrated child apps
  if (document.getElementsByTagName('app-login-page')[0]) {
    document.getElementsByTagName('app-login-page')[0].style.display = 'none';
  }
});

/**
 * We want to gate off functionality - and even the ability to load apps/packages - for practice users and client users depening on their type.
 * This ensures we wont have users of one type able to load the other type's MFEs. eg. /client-management wont be available to
 * client users. This is a 'soft' gate, pending a more robust architectural solution.
 */
function initApp(userType = JSON.parse(localStorage.getItem('ias_user')).userType, environment) {
  if (userType === 'client') {
    appClientPortal(environment);
  } else if (userType === 'practice') {
    appMain(environment);
  } else {
    // Oh no...
    throw new Error(`hostApp: Unknown user type on 'ias_user'. This should be set by the authentication-signin-component.`);
  }
}

/**
 * Simple fn that throws if we don't have the necessary data to init.
 */
function checkCanInit(iasUserData) {

  if (!iasUserData)
    throw new Error('hostApp: No user data in local storage. Redirecting to login page.');

  if (!iasUserData.jwtExpiryMs || iasUserData.jwtExpiryMs < Date.now())
    throw new Error('hostApp: No expiry info or expired jwt in local storage. Redirecting to login page.');

  // Questionable?.. It covers situations in the initial transition when these changes go live and a user may be logged in
  // but with old userData set by the auth-signin package...
  if (!iasUserData.userType)
    throw new Error('hostApp: No user type in local storage. Redirecting to login page.');

}



const authStageRoutes = ['/login', '/logout', '/logincallback', '/session-signout', '/signup', '/missing-profile'];

// If we're in the auth flow we don't want to end up redirecting to the login page
const shouldRedirectToLogin = curPath => !authStageRoutes.some(path => curPath.startsWith(path));

// Confirm we're okay returning to a given path, as some could lead to undesired behaviour (eg. loops in auth flow)
const shouldReturnTo = curPath =>
  curPath !== '/' && // we return here by default anyway
  curPath !== '/404' && // no point returning to 404
  shouldRedirectToLogin(curPath) // confirms we're not in the auth flow, as we don't want to redirect to it
