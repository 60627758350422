
import * as singleSpa from 'single-spa';

import eventBus from './../event-bus';
import features from './../features';

import {
    showWhenPrefix,
    load
  } from './../root-methods.util';

export default function appClientPortal(environment) {
    console.log('hostApp: loading as client user. app-client-portal.js loaded.');

    singleSpa.registerApplication(
        'senta-react-client-portal',
        () => load('@iris/elements2-senta-react-client'),
        showWhenPrefix(['/']),
        { environment, eventBus, features, component: 'client-portal' }
    );

}
