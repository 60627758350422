/*
 * FILE:
 * - root-methods.util.js
 *
 * DESCRIPTION:
 * - This file is to be used for any functions needed within the root-application.js in an
 *   attempt to keep the root-application file smaller and more manageable.
 *
*/

export const showWhenAnyOf = (routes) => {
    return function (location) {
      return routes.some((route) => location.pathname === route);
    };
}

export const showWhenPrefix = (routes) => {
    return function (location) {
      return routes.some((route) => location.pathname.startsWith(route));
    };
}

export const showExcept = (routes) => {
    return function (location) {
      return routes.every((route) => !location.pathname.startsWith(route));
    };
}

export const showWhenAnyOfAndNotTaxfiler = (routes) => {
    return function (location) {
      return routes.some((route) => location.pathname === route && location.search.indexOf("?csp") == -1);
    };
}

export const showWhenPrefixWithParameters = (routes) => {
    return function (location) {
      // map routes into regex strings, with each parameter getting replaced with a wildcard
      let regexRoutes = [];
      routes.forEach((route) => {
        route = route.replaceAll(/:([^/])+\//g, ')(.+\/)(');
        regexRoutes.push(`(${route}).*`);
      });

      regexRoutes.some(route => {
        return location.pathname.match(route)
      })

      // attempt to match url to each regex
      return regexRoutes.some((route) => location.pathname.match(route));
    };
}

export const showWhenSentaRoute = () => location => {
  const url = location.pathname;
  const sentaPathStarters = ['/c/', '/s/', '/t/', '/j/', '/f/', '/e/o', '/e/e', '/e/m', '/e/s', '/e/x', '/user/profile/email'];
  return !!sentaPathStarters.find(ps => url.startsWith(ps));
}

export const showWhenClientViewRoute = () => location => {
  const url = location.pathname;
  const pathrx = /^\/client-management\/\w+\/[a-fA-F0-9-]{36}\/.+/
  // the clients prospects unmounts a bunch of dom when adding a relationship (/relationships/add), making
  // any packages targeting the client view homeless and break. So just unmount in that case
  const relationshipsUnmountBlah = /^\/client-management\/\w+\/[a-fA-F0-9-]{36}\/relationships\/\S+/

  const isClientDetailsPath = !!url.match(pathrx);
  const isUnmountedClientDetailsPath = url.match(relationshipsUnmountBlah);

  return isClientDetailsPath && !isUnmountedClientDetailsPath;
}

export const showWhenTaxfilerRoute = () => {
    return function (location) {
      return (location.pathname.startsWith("/client-management/") && location.pathname.endsWith("/taxfiler"));
    }
}

export const showWhenAnyOfOrHashMatch = (routes) => {
  return function (location) {
    return routes.some((route) => location.pathname === route || (location.pathname + location.hash === route ));
  }
}

export const load = (module, moduleDefault = true) => {
  return System.import(module).then(m => moduleDefault ? m.default : m);
}

export const showOnlyExactMatch = (routes) => {
  return function (location) {
    return routes.some((route) => location.pathname === route);
  }
}
